import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import EapButton from '../components/EapButton';
import PasswordAndConfirm from '../formComponents/PasswordAndConfirm';
import Password from '../formComponents/Password';
import InlineValidationMessage from '../components/InlineValidationMessage';

export default class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      },
      dirty: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.formIsNotValid()) {
      this.setState({ dirty: true });
      return;
    }

    this.setState({ dirty: false });

    const updatedUser = _.pick(this.props.user, [
      'username', 'email', 'firstName', 'lastName', 'gender', 'dob', 'grade', 'password'
    ]);
    updatedUser.email = updatedUser.email || '';
    if (updatedUser.gender !== 'M' || updatedUser.gender !== 'F') {
      updatedUser.gender = undefined;
    }
    this.props.updateSignin({
      ...updatedUser,
      gender: updatedUser.gender,
      grade: updatedUser.grade === null ? undefined : updatedUser.grade,
      dob: updatedUser.dob === null ? undefined : moment(updatedUser.dob).format('yyyy-MM-dd'),
      password: this.state.password.value,
      currentPassword: this.state.currentPassword.value
    });
  }

  formIsNotValid() {
    return (
      !this.state.password.isValid ||
      !this.state.currentPassword.isValid ||
      this.props.loading
    );
  }

  render() {
    const formGroupStyle = 'pure-u-lg-1-2 pure-u-md-1-2 pure-u-1';
    let badPassword;
    if (
      !this.state.dirty &&
      !this.props.loading &&
      this.props.updateError === 'Password did not match'
    ) {
      badPassword = <InlineValidationMessage message="Your current password is incorrect." />;
    }
    return (
      <div className="update-signin-page">
        <div className="pure-u-1 center">
          <h1>Update Password</h1>
        </div>
        <div className="pure-u-1 form-container">
          <form
            id="edit-signin-form"
            className="pure-form pure-form-stacked eap-grey-form"
            onSubmit={this.handleSubmit}
          >
            { true &&
            <input
              className="screen-reader"
              disabled
              autoComplete="username"
              value={this.props.user.username}
            />
            }
            <div className="pure-g">
              <PasswordAndConfirm
                passwordKeyName="new-password"
                confirmPassword="confirm-password"
                update={password => this.setState({ password: { ...password }, dirty: true })}
              />
              <div className="pure-u-1-1">
                <Password
                  displayLabel="CURRENT PASSWORD"
                  update={password => this.setState({
                      currentPassword: { ...password },
                      dirty: true
                    })
                  }
                  keyName="current-password"
                  showError={false}
                  showErrorOnInit
                />
              </div>
            </div>
            { badPassword }
            <div className={formGroupStyle}>
              <EapButton
                isSubmit
                id="submit-btn"
                disabled={this.props.loading}
                inProgress={this.props.loading}
                text="UPDATE"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

UpdatePassword.propTypes = {
  updateSignin: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    grade: PropTypes.number,
    dob: PropTypes.string,
  }).isRequired,
  updateError: PropTypes.string,
  loading: PropTypes.bool
};

UpdatePassword.defaultProps = {
  updateError: undefined,
  loading: false
};
