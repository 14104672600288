/* eslint jsx-a11y/label-has-associated-control: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as yup from 'yup';
import { parseISO } from 'date-fns';
import { Formik, Field } from 'formik';

import {
  CoreUserProfileSchema,
  RequiredEmailSchema
} from '../signup/components/UserSchema';
import { scrubUserData } from '../utils/FormsHelper';
import InlineValidationMessage from '../components/InlineValidationMessage';
import EapButton from '../components/EapButton';
import EapDatePicker from '../components/EapDatePicker';
import FormInputGroup from '../components/FormInputGroup';
import StyledForm from '../components/StyledForm';
import GradeLevelSelectList from '../components/GradeLevelSelectList';
import GenderSelectList from '../components/GenderSelectList';

const EditProfileForm = ({
  initialValues,
  onUpdateProfile
}) => {
  const [userDoesNotHaveEmail, setUserDoesNotHaveEmail] = useState(_.isEmpty(initialValues.email));

  const profileSchema = CoreUserProfileSchema.concat(
    yup
      .object()
      .shape({
        password: yup.string().required('Please enter your current password'),
        email: !userDoesNotHaveEmail && RequiredEmailSchema,
      })
  );

  const user = _.pick(
    initialValues,
    ['username', 'email', 'gender', 'firstName', 'lastName', 'dob', 'grade']
  );

  return (
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={{
        ...user,
        gender: _.isEmpty(user.gender) ? '' : user.gender,
        grade: user.grade || '',
        // Need to handle date special here in editing forms where date already exists
        dob: user.dob === null ? undefined : parseISO(user.dob),
        password: '',
      }}
      validationSchema={profileSchema}
      onSubmit={(values) => {
        const scrubbed = scrubUserData(values);
        onUpdateProfile(scrubbed);
      }}
    >
      {
        ({
          errors,
          touched,
          setFieldValue,
          setFieldTouched
        }) => (
          <StyledForm>
            <FormInputGroup required>
              <label htmlFor="username">USERNAME</label>
              <Field
                type="text"
                name="username"
              />
              {
                touched.username && errors.username && (
                  <InlineValidationMessage message={errors.username} />
                )
              }
            </FormInputGroup>
            <FormInputGroup required={!userDoesNotHaveEmail}>
              <label htmlFor="email">EMAIL</label>
              <Field
                disabled={userDoesNotHaveEmail}
                type="text"
                name="email"
              />
              {
                touched.email && errors.email && (
                  <InlineValidationMessage message={errors.email} />
                )
              }
              <div>
                <label htmlFor="userDoesNotHaveEmail">
                  <input
                    type="checkbox"
                    name="userDoesNotHaveEmail"
                    checked={userDoesNotHaveEmail}
                    onChange={() => {
                      setUserDoesNotHaveEmail(!userDoesNotHaveEmail);
                      setFieldValue('email', '');
                      setFieldTouched('email', false);
                    }}
                  />
                  User does not have email
                </label>
              </div>
            </FormInputGroup>
            <FormInputGroup required>
              <label htmlFor="firstName">FIRST NAME</label>
              <Field
                type="text"
                name="firstName"
              />
              {
                touched.firstName && errors.firstName && (
                  <InlineValidationMessage message={errors.firstName} />
                )
              }
            </FormInputGroup>
            <FormInputGroup required>
              <label htmlFor="lastName">LAST NAME</label>
              <Field
                type="text"
                name="lastName"
              />
              {
                touched.lastName && errors.lastName && (
                  <InlineValidationMessage message={errors.lastName} />
                )
              }
            </FormInputGroup>
            <FormInputGroup>
              <label htmlFor="dob">
                DATE OF BIRTH (MM/DD/YYYY)
              </label>
              <EapDatePicker
                initialDate={user.dob === null ? undefined : user.dob}
                onDateSelected={(newDate) => setFieldValue('dob', newDate)}
              />
            </FormInputGroup>
            <FormInputGroup>
              <label htmlFor="gender">GENDER</label>
              <GenderSelectList />
            </FormInputGroup>
            <FormInputGroup>
              <label htmlFor="grade">GRADE LEVEL</label>
              <GradeLevelSelectList />
            </FormInputGroup>
            <div />
            <FormInputGroup required>
              <label htmlFor="password">CURRENT PASSWORD</label>
              <Field
                type="password"
                name="password"
              />
              {
                touched.password && errors.password && (
                  <InlineValidationMessage message={errors.password} />
                )
              }
            </FormInputGroup>
            <div />
            <FormInputGroup>
              <EapButton
                text="UPDATE"
                isSubmit
              />
            </FormInputGroup>
          </StyledForm>
        )
      }
    </Formik>
  );
};

EditProfileForm.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    gender: PropTypes.string,
    grade: PropTypes.number
  }),
  onUpdateProfile: PropTypes.func.isRequired
};

EditProfileForm.defaultProps = {
  initialValues: {
    email: '',
    grade: '',
    gender: ''
  }
};

export default EditProfileForm;
