import { connect } from 'react-redux';
import UpdatePassword from './UpdatePassword';
import { updateSignin } from '../actions/user';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    updateError: state.user.updateError,
    loading: state.user.loading,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSignin: (updatedUserInfo) => {
    dispatch(updateSignin(updatedUserInfo));
  }
});

const UpdatePasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePassword);

export default UpdatePasswordContainer;
