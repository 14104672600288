import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EapModal from '../../../components/EapModal';
import Button from '../../../components/EapButton';
import { StaticEapTable } from '../../../components/StaticEapTable';
import SectionHeader from '../../components/SectionHeader';
import * as EapDisplayDate from '../../../components/EapDisplayDate';
import AdminIconList from '../../../components/AdminIconList';
import calculateActionsWidth from '../../../utils/EapTableHelper';
import AddProductForm from './AddProductForm';
import AdminDeleteProductModal from './AdminDeleteProductModal';
import AdminTransferProductModalContainer from '../AdminTransferProductModalContainer';
import PasscodeRegenerationDialogContainer from '../../../components/PasscodeRegenerationDialogContainer';
import AdminIcon from '../../../components/AdminIcon';
import AddSubtractProductModal from './AddSubtractProductModal';

const GroupProductsTable = ({
  group,
  products,
  relatedGroups,
  addNewProduct,
  incrementAvailableProducts,
  editPasscode,
  editingPasscode,
  editPasscodeError,
  clearEditPasscodeError
}) => {
  const [showAddSubModal, setShowAddSubModal] = useState();
  const [showEditPasscodeModal, setShowEditPasscodeModal] = useState();
  React.useEffect(() => {
    if (!showEditPasscodeModal) {
      clearEditPasscodeError();
    }
  }, [showEditPasscodeModal]);
  const columns = [
    {
      Header: 'Code',
      accessor: 'code'
    },
    {
      Header: 'Examinee Product',
      accessor: 'name'
    },
    {
      Header: 'Admin Product',
      accessor: 'adminName'
    },
    {
      Header: 'Passcode',
      accessor: 'passcode'
    },
    {
      Header: 'Amount',
      accessor: 'amount'
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      filterable: false,
      Cell: ({ row }) => <EapDisplayDate.Display date={row.original.updatedAt} />,
      sort: EapDisplayDate.sort
    },
    {
      Header: 'Actions',
      filterable: false,
      sortable: false,
      maxWidth: calculateActionsWidth({ numberOfIcons: 5 }),
      accessor: '',
      Cell: ({ row }) => (
        <AdminIconList>
          <AdminIcon
            onClick={() => setShowAddSubModal({
              type: 'add',
              product: {
                ...row.original
              },
            })}
            icon="fa-plus"
            toolTip="Add Product"
          />
          <AdminIcon
            onClick={() => setShowAddSubModal({
              type: 'subtract',
              product: {
                ...row.original
              },
            })}
            icon="fa-minus"
            toolTip="Subtract Product"
          />
          <AdminDeleteProductModal
            groupName={group.name}
            availableProduct={row.original}
            onProductDeleted={() => {}}
            groupId={group.id}
          />
          <AdminTransferProductModalContainer
            organizationId={group.organizationId}
            amount={row.original.amount}
            allowedGroups={relatedGroups}
            availableProductId={row.original.id}
            onProductsTransferred={() => {}}
            groupId={group.id}
          />
          <PasscodeRegenerationDialogContainer
            availableProduct={row.original}
            groupId={group.id}
          />
          <AdminIcon
            onClick={() => { setShowEditPasscodeModal(row.original); }}
            icon="fa-pencil-square-o"
            toolTip="Edit Passcode"
          />
        </AdminIconList>
      )
    }
  ];

  return (
    <div className="pure-u-1">
      <StaticEapTable
        columns={columns}
        data={products}
      />
      <SectionHeader title="Add Product(s)" />
      <AddProductForm
        onSubmit={({ amount, product }) => {
          addNewProduct(group.id, product.id, amount);
        }}
      />
      <AddSubtractProductModal
        type={showAddSubModal ? showAddSubModal.type : ''}
        availableProduct={showAddSubModal ? showAddSubModal.product : {}}
        onClose={({ amount } = {}) => {
          if (amount) {
            incrementAvailableProducts(
              showAddSubModal.product.id,
              showAddSubModal.product.groupId,
              amount,
            );
          }
          setShowAddSubModal(undefined);
        }}
        showModal={showAddSubModal}
      />
      <EapModal
        showModal={showEditPasscodeModal !== undefined}
        modalTitle="Edit Passcode"
      >
        <form
          className="pure-form pure-form-stacked"
          onSubmit={(e) => {
            e.preventDefault();
            editPasscode(
              showEditPasscodeModal.id,
              e.target.passcode.value,
            ).then(() => {
              setShowEditPasscodeModal(undefined);
            }).catch(() => {});
          }}
        >
          {editPasscodeError && <div className="eap-error">{editPasscodeError}</div>}
          <input
            className="pure-u-1"
            type="text"
            name="passcode"
            placeholder="Enter new passcode"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button text="Cancel" onClick={() => setShowEditPasscodeModal(undefined)} isSecondary id="cancelEditPasscode" />
            <Button text="Submit" isSubmit id="submitEditPasscode" inProgress={editingPasscode} />
          </div>
        </form>
      </EapModal>
    </div>
  );
};

GroupProductsTable.propTypes = {
  addNewProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  group: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  relatedGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
};

GroupProductsTable.defaultProps = {
  products: undefined,
};

export default GroupProductsTable;
