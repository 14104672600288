import _ from 'lodash';
import {
  isValid as isValidDate,
  format
} from 'date-fns';

// Takes userData straight out of forms and makes it acceptable to the server
export function scrubUserData(userData) {
  let dob;
  console.log(userData.dob, isValidDate(userData.dob));
  if (userData.dob && isValidDate(userData.dob)) {
    dob = format(userData.dob, 'yyyy-MM-dd');
    console.log('formatted dob', dob);
  }
  return {
    ...userData,
    email: _.isEmpty(userData.email) ? undefined : userData.email,
    gender: userData.gender ? userData.gender : undefined,
    grade: userData.grade ? userData.grade : undefined,
    dob,
  };
}

export function checkFormValidity(formValues) {
  return _.reduce(
    formValues,
    (isValid, formComponents) => (isValid && formComponents.isValid),
    true
  );
}
