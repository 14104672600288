/*eslint react/no-unstable-nested-components:0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProductTransferDialog from '../../components/ProductTransferDialog';
import { StaticEapTable, DefaultFilter } from '../../components/StaticEapTable';
import ConfirmationModal from '../../components/ConfirmationModal';
import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';
import ProgressSpinner from '../../components/ProgressSpinner';

const AdminAvailableProducts = ({
  group,
  loading,
  availableProducts,
  destinationGroups,
  loadGroupInfo,
  transferProducts,
  generateNewPasscode,
  errorMessage
}) => {
  const [showModal, setShowModal] = useState({ type: '', data: {} });

  useEffect(() => {
    loadGroupInfo();
  }, []);

  if (loading) {
    return (
      <>
        <p> Loading your group.</p>
        <ProgressSpinner />
      </>
    );
  }

  if (!group.permissions.products.view) {
    return (
      <p> You do not have permissions to view products of this group.</p>
    );
  }
  const columns = [
    {
      Header: 'Product',
      accessor: 'adminName',
      Filter: DefaultFilter
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Filter: DefaultFilter
    },
    {
      Header: 'Passcode',
      accessor: 'passcode',
      Filter: DefaultFilter
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        const allowPurchase = row.original.price !== null;
        return (
          <AdminIconList>
            {
              destinationGroups.length > 0 && (
                <AdminIcon
                  onClick={() => setShowModal({ type: 'transfer', data: row.original })}
                  icon="fa-random"
                  toolTip="Transfer Products"
                  permission="transfer"
                  permissions={group.permissions.products}
                />
              )
            }
            <AdminIcon
              onClick={() => setShowModal({ type: 'passcode', data: row.original })}
              icon="fa-rotate-right"
              toolTip="Generate New Passcode"
              permission="transfer"
              permissions={group.permissions.products}
            />
            {
              allowPurchase && (
                <AdminIcon
                  icon="fa-dollar"
                  toolTip="Buy More Products"
                  url={`/group/${group.id}/available-product/${row.original.id}/purchase`}
                  permission="purchase"
                  permissions={group.permissions.products}
                />
              )
            }
          </AdminIconList>
        );
      }
    }
  ];
  return (
    <>
      <StaticEapTable
        columns={columns}
        data={availableProducts}
      />
      <ProductTransferDialog
        dialogTitle="TRANSFER PRODUCTS"
        groups={destinationGroups}
        availableAmount={showModal.data.amount || 0}
        showDialog={showModal.type === 'transfer'}
        handleClose={() => setShowModal({ type: '', data: {} })}
        transferProducts={(amountAdded, targetGroupId) => {
          const sourceAdminGroupId = group.permissions.id;
          const availableProduct = showModal.data;
          const targetGroup = destinationGroups
            .find((g) => (g.id.toString() === targetGroupId.toString()));
          transferProducts(
            {
              id: sourceAdminGroupId,
              groupId: availableProduct.groupId
            },
            targetGroup,
            availableProduct,
            amountAdded
          );
          setShowModal({ type: '', data: {} });
        }}
      />
      <ConfirmationModal
        modalTitle="GENERATE NEW PASSCODE"
        showModal={showModal.type === 'passcode'}
        messagePrompt={(
          <p>
            Are you sure you want to generate a new passcode for
            <b> { showModal.data.adminName }</b>
          </p>
        )}
        closeModal={() => setShowModal({ type: '', data: {} })}
        confirmAction={() => generateNewPasscode(group.permissions.id, showModal.data.id)}
        errorMessage={errorMessage}
      />
    </>
  );
};

AdminAvailableProducts.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number,
    organization: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    permissions: PropTypes.shape({
      id: PropTypes.number,
      products: PropTypes.shape({
        assign: PropTypes.bool,
        transfer: PropTypes.bool,
        view: PropTypes.bool
      })
    }).isRequired,
  }).isRequired,
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    adminName: PropTypes.string,
    amount: PropTypes.number,
    passcode: PropTypes.string
  })).isRequired,
  errorMessage: PropTypes.string,
  user: PropTypes.shape({
    adminGroups: PropTypes.array
  }).isRequired,
  transferProducts: PropTypes.func.isRequired,
  generateNewPasscode: PropTypes.func.isRequired,
  loadGroupInfo: PropTypes.func.isRequired
};

AdminAvailableProducts.defaultProps = {
  errorMessage: undefined
};

export default AdminAvailableProducts;
