import { connect } from 'react-redux';

import {
  addNewProduct,
  incrementAvailableProducts,
  updatePasscode,
  clearEditPasscodeError,
} from '../../actions/availableProducts';

import GroupProductsTable from './components/GroupProductsTable';

const mapStateToProps = (state) => {
  if (state.group.info === undefined) {
    return {
      group: {},
      products: [],
      relatedGroups: [],
    };
  }
  const group = state.group.info;
  const products = state.availableProducts.list.filter((prod) => (prod.groupId === group.id));
  const relatedGroups = state.groups.list.filter((g) => (
    g.organizationId === group.organizationId
  ));

  return {
    products,
    editingPasscode: state.availableProducts.loading,
    editPasscodeError: state.availableProducts.errorMessage,
    group,
    relatedGroups,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewProduct: (
    groupId,
    productId,
    amount
  ) => dispatch(addNewProduct(groupId, productId, amount)),
  incrementAvailableProducts: (
    availableProductId,
    groupId,
    amount
  ) => dispatch(incrementAvailableProducts(availableProductId, groupId, amount)),
  editPasscode: (productId, passcode) => (dispatch(updatePasscode(productId, passcode))),
  clearEditPasscodeError: () => dispatch(clearEditPasscodeError),
});

const GroupProductsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupProductsTable);

export default GroupProductsTableContainer;
