import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import EditProfileForm from './EditProfileForm';
import SensibleContainer from '../components/SensibleContainer';
import EapThrone from '../components/EapThrone';
import FormHeader from '../components/FormHeader';
import EapButton from '../components/EapButton';
import ConfirmationModal from '../components/ConfirmationModal';

import './EditSignin.css';

import CleverLogo from '../images/partners/CleverLogo.png';

const EditSignin = ({
  user,
  oauth,
  updateSignin,
  removeOauth
}) => {
  const [updateError, setUpdateError] = useState(undefined);
  const [removeModal, setRemoveModal] = useState(undefined);
  useEffect(() => {
    setUpdateError(user.updateError);
  }, [user.updateError]);
  return (
    <SensibleContainer>
      <EapThrone className="update-signin-page">
        <div className="pure-u-3-4">
          <FormHeader
            headerText="Update Sign-in"
          />
          <div className="pure-u-1-1">
            <EditProfileForm
              initialValues={user}
              onUpdateProfile={(values) => updateSignin({
                ...values,
                currentPassword: values.password
              })}
            />
          </div>
        </div>
        <div className="pure-u-3-4 form-container">
          {updateError}
        </div>
      </EapThrone>
      {
        oauth && oauth.length > 0 && (
          <div
            className="pure-u-3-4"
            style={{
              letterSpacing: 'normal',
              margin: 'auto',
            }}
          >
            <h2>Linked Sign-in Providers</h2>
            {oauth.map((oa) => (
              <div
                key={oa.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '10px 0',
                }}
              >
                {
                  oa.provider === 'clever' && (
                    <img
                      src={CleverLogo}
                      height="20px"
                      alt="Clever Logo"
                      style={{
                        marginRight: '10px',
                      }}
                    />
                  )
                }
                <EapButton text="Remove" onClick={() => setRemoveModal(oa.id)} />
              </div>
            ))}
          </div>
        )
      }
      <ConfirmationModal
        modalTitle="Remove Sign-in Provider"
        showModal={removeModal !== undefined}
        messagePrompt={<p>Are you sure you want to remove this sign-in provider?</p>}
        closeModal={() => setRemoveModal(undefined)}
        confirmAction={() => {
          removeOauth(removeModal);
          setRemoveModal(undefined);
        }}
      />
    </SensibleContainer>
  );
};

EditSignin.propTypes = {
  updateSignin: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    grade: PropTypes.number,
    dob: PropTypes.string,
    updateError: PropTypes.string
  }).isRequired
};

export default EditSignin;
