import React from 'react';
import { useLocation } from 'react-router-dom';

const OauthRquestComponent = () => {
  const { search } = useLocation();
  const parsedSearch = new URLSearchParams(search);
  let provider;
  switch (parsedSearch.get('provider')) {
    case 'clever':
      provider = 'Clever';
      break;
    default:
      provider = '';
      break;
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1>Approval Requested</h1>
      <p>
        An email has been sent to you with a link to approve your {provider} login.
        Please click on the link enclosed in your email to continue.
      </p>
    </div>
  );
};

export default OauthRquestComponent;
