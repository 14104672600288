/* eslint import/no-cycle:0 default-param-last:0 */
import _ from 'lodash';
import moment from 'moment';
import {
  FILTER_USERS_SUCCESS,
  USER_DATA_REQUEST,
  USER_DATA_RECEIVED,
  USER_DATA_ERROR,
  PASSCODE_SUCCESS,
  PASSCODE_FAILURE,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  SIGNIN_UPDATE_REQUEST,
  SIGNIN_UPDATE_SUCCESS,
  SIGNIN_UPDATE_FAILURE,
  SUPER_CREATE_SIGNIN_SUCCESS,
  SUPER_CREATE_SIGNIN_FAILURE,
  SUPER_LOAD_USER_SUCCESS,
  SUPER_UPDATE_USER_SUCCESS,
} from '../actions/user';

import {
  ADD_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS
} from '../actions/group';

import {
  ADD_ADMIN_SUCCESS,
  SUPER_LOAD_GROUP_ADMINS_SUCCESS
} from '../actions/admins';

import { ACCEPT_ADMIN_INVITE_SUCCESS } from '../actions/adminInvites';

import { ACCEPT_EXAMINEE_INVITE_SUCCESS } from '../actions/examineeInvites';

import { getPermissionString } from '../components/PermissionSelector';

import {
  GET_SUPER_GROUP_EXAMINEES_SUCCESS,
  SUPER_ADD_EXAMINEE_SUCCESS,
  SUPER_DELETE_EXAMINEE_SUCCESS,
} from '../actions/examinees';

import {
  FULFILLMENT_SUCCESS,
  REFILL_SUCCESS,
} from '../actions/purchase';

const cleanPayload = (input) => {
  const cleaned = _.pick(input, [
    'id',
    'username',
    'email',
    'firstName',
    'lastName',
    'isActive',
    'isSuperAdmin',
    'grade',
    'dob',
    'gender',
    'stripeCustomerId',
    'qboCustomerId',
    'qualified',
    'ipAddress',
    'city',
    'state',
    'zip',
    'country',
    'createdAt',
    'updatedAt'
  ]);
  cleaned.userId = cleaned.id;
  cleaned.adminGroups = [];
  cleaned.createdAt = moment(cleaned.createdAt);
  cleaned.updatedAt = moment(cleaned.updatedAt);
  return cleaned;
};

function adminPermissions(adminGroup) {
  const cleaned = {
    ...{},
    ...adminGroup
  };
  _.each(cleaned, (val, key) => {
    if (_.includes(key, 'per_')) {
      cleaned[key] = val;
    }
  });
  cleaned.permissionLabel = getPermissionString(cleaned);
  return cleaned;
}

const userReducer = (
  state = {
    loaded: '',
    passwordResetExpired: false,
    list: [],
    serverFiltered: []
  },
  action
) => {
  switch (action.type) {
    case USER_DATA_RECEIVED: {
      const cleanedActions = { ...{}, ...action.payload, updateError: undefined };
      cleanedActions.adminGroups = cleanedActions.adminGroups.map((g) => adminPermissions(g));
      return { ...state, ...(cleanedActions), ...{ loaded: 'complete' } };
    }
    case USER_DATA_ERROR: {
      return { ...state, ...{ loaded: '' } };
    }
    case USER_DATA_REQUEST: {
      return { ...state, ...{ loaded: 'inprogress' } };
    }
    case PASSCODE_SUCCESS: {
      return {
        ...state,
        permissions: { ...state.permissions, examinee: true },
        assignedProducts: state.assignedProducts.concat(action.payload.product),
        message: ''
      };
    }
    case PASSCODE_FAILURE: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    case ACCEPT_ADMIN_INVITE_SUCCESS: {
      const cleanedAdminGroup = adminPermissions(action.payload.adminGroup);
      const newState = { ...state };
      newState.adminGroups = newState.adminGroups.concat(cleanedAdminGroup);
      return newState;
    }
    case ACCEPT_EXAMINEE_INVITE_SUCCESS: {
      const newState = { ...state };
      newState.assignedProducts = newState.assignedProducts.concat(action.payload.assignedProduct);
      return newState;
    }
    case ADD_GROUP_SUCCESS: {
      const newGroup = adminPermissions(action.payload.newAdminGroup);
      newGroup.availableProducts = newGroup.availableProducts || [];
      return {
        ...state,
        adminGroups: state.adminGroups.concat(newGroup)
      };
    }
    case UPDATE_GROUP_SUCCESS: {
      const newState = { ...state };
      const groupIndex = _.findIndex(
        newState.adminGroups,
        { groupId: action.payload.id }
      );

      // Coping with side-effects/mutation for super admins
      if (!_.isEmpty(newState.adminGroups[groupIndex])) {
        newState.adminGroups[groupIndex].name = action.payload.name;
        newState.adminGroups[groupIndex].description = action.payload.description;
      }
      return newState;
    }
    case SIGNIN_UPDATE_REQUEST: {
      return { ...state, loading: true };
    }
    case SIGNIN_UPDATE_SUCCESS: {
      return { ...state, loading: false };
    }
    case SIGNIN_UPDATE_FAILURE: {
      return { ...state, updateError: action.payload.message, loading: false };
    }
    case UPDATE_PASSWORD: {
      return {
        ...state,
        updateFailedMessage: undefined,
        passwordResetExpired: false
      };
    }
    case UPDATE_PASSWORD_FAILED: {
      return {
        ...state,
        updateFailedMessage: action.payload.message,
        passwordResetExpired: action.payload.expired
      };
    }
    case SUPER_CREATE_SIGNIN_SUCCESS: {
      return { ...state, createSigninError: undefined };
    }
    case SUPER_CREATE_SIGNIN_FAILURE: {
      return { ...state, createSigninError: action.payload.error };
    }
    case FILTER_USERS_SUCCESS: {
      const filtered = _.map(action.payload, (value) => ({
        ...value,
        userId: value.id,
        createdAt: moment(value.createdAt),
        updatedAt: moment(value.updatedAt)
      }));
      return { ...state, serverFiltered: filtered };
    }
    case ADD_ADMIN_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push(cleanPayload(action.payload.newAdmin));
      return {
        ...state,
        list
      };
    }
    case SUPER_LOAD_GROUP_ADMINS_SUCCESS: {
      const admins = _.uniqBy([
        ..._.map(action.payload.admins, (a) => cleanPayload(a)),
        ..._.cloneDeep(state.list)
      ], 'id');
      return { ...state, list: admins };
    }
    case GET_SUPER_GROUP_EXAMINEES_SUCCESS: {
      const users = _.uniqBy([
        ..._.map(action.payload.examinees, (a) => cleanPayload(a)),
        ..._.cloneDeep(state.list)
      ], 'id');
      return { ...state, list: users };
    }
    case SUPER_ADD_EXAMINEE_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const user = _.cloneDeep(action.payload.user);
      user.userId = user.id;
      user.adminGroups = [];
      user.createdAt = moment(user.createdAt);
      user.updatedAt = moment(user.updatedAt);
      list.push(user);
      return { ...state, list: _.uniqBy(list, 'userId') };
    }
    case SUPER_DELETE_EXAMINEE_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.remove(list, { userId: action.payload.examinee.userId });
      return { ...state, list };
    }
    case SUPER_LOAD_USER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push(cleanPayload(action.payload.userData));
      return { ...state, list: _.uniqBy(list, 'id') };
    }
    case SUPER_UPDATE_USER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const userIndex = _.findIndex(list, { id: action.payload.userId });
      list[userIndex] = {
        ...list[userIndex],
        ...action.payload.updatedUserInfo
      };
      return { ...state, list: _.uniqBy(list, 'id') };
    }
    case FULFILLMENT_SUCCESS: {
      const newState = _.cloneDeep(state);
      const {
        response,
        group,
      } = action.payload;
      const gr = newState.adminGroups.filter((g) => g.groupId === group.groupId);
      if (gr.length !== 1) {
        return newState;
      }
      gr[0].availableProducts = response;
      return newState;
    }
    case REFILL_SUCCESS: {
      const newState = _.cloneDeep(state);
      const {
        refill: {
          groupId,
          quantity,
        },
        availableProductId,
      } = action.payload;
      const gr = newState.adminGroups.filter((g) => g.groupId === groupId);
      if (gr.length !== 1) {
        return newState;
      }
      const avail = gr[0].availableProducts.filter((a) => a.id === availableProductId);
      if (avail.length !== 1) {
        return newState;
      }
      avail[0].amount += quantity;
      return newState;
    }
    default: {
      return { ...state };
    }
  }
};

export default userReducer;
