/* eslint default-param-last: 0 */
import {
  LOGIN_REQUESTED,
  LOGIN_REQUEST_FAILED,
  LOGIN_AUTHENTICATED,
  LOGIN_REJECTED,
  OAUTH_APPROVAL_REQUEST,
} from '../actions/login';

const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED: {
      return { ...state, authenticated: undefined };
    }
    case LOGIN_AUTHENTICATED: {
      if (action.payload.authenticated) {
        return { ...state, authenticated: true };
      }
      return { ...state, authenticated: false };
    }
    case LOGIN_REJECTED: {
      return { ...state, authenticated: action.payload.authenticated };
    }
    case LOGIN_REQUEST_FAILED: {
      return { ...state, err: action.payload.err };
    }
    case OAUTH_APPROVAL_REQUEST: {
      return { ...state, oauthApprovalRequest: true };
    }
    default: {
      return { ...state };
    }
  }
};

export default loginReducer;
