import React from 'react';
import PropTypes from 'prop-types';

import EapModal from './EapModal';
import EapButton from './EapButton';
import './ConfirmationModal.css';

/*
 * @params
 *  modalTitle {string} - title to be displayed as header
 *  showModal {bool} - determines whether to open or close modal
 *  messagePrompt {jsx} - JSX element with prompt for the user
 *  closeModal {func} - Function to change showModal bool
 *  confirmAction {func} - Function to run when user confirms 'yes'
 */
const ConfirmationModal = ({
  modalTitle,
  showModal,
  messagePrompt,
  closeModal,
  confirmAction,
}) => (
  <span>
    <EapModal
      modalTitle={modalTitle}
      showModal={showModal}
    >
      <div className="modal-center">
        {messagePrompt}
        <EapButton
          text="Yes"
          onClick={() => { closeModal(); confirmAction(); }}
        />
        &nbsp;
        <EapButton
          text="No"
          isSecondary
          onClick={closeModal}
        />
      </div>
    </EapModal>
  </span>
);

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  messagePrompt: PropTypes.element.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};
