import { httpClient } from '../utils/httpClient';
import { publishServerError } from './error';
import redirect from '../utils/redirectUrl';

import { loginAuthenticated } from './login';

export const OAUTH_APPROVAL_REQUEST = 'OAUTH_APPROVAL_REQUEST';
export const OAUTH_APPROVAL_SUCCESS = 'OAUTH_APPROVAL_SUCCESS';
export const OAUTH_APPROVAL_FAILURE = 'OAUTH_APPROVAL_FAILURE';

export const OAUTH_REMOVE_REQUEST = 'OAUTH_REQUEST_REMOVE';
export const OAUTH_REMOVE_SUCCESS = 'OAUTH_REQUEST_SUCCESS';
export const OAUTH_REMOVE_FAILURE = 'OAUTH_REQUEST_FAILURE';

export const OAUTH_UPDATE_REQUEST = 'OAUTH_UPDATE_REQUEST';
export const OAUTH_UPDATE_SUCCESS = 'OAUTH_UPDATE_SUCCESS';
export const OAUTH_UPDATE_FAILURE = 'OAUTH_UPDATE_FAILURE';

export const approveOAuthRequest = ({ code }) => (dispatch) => {
  dispatch({ type: OAUTH_APPROVAL_REQUEST });
  return httpClient.post('/oauth-approval', { code })
    .then((response) => response.data)
    .then((data) => {
      dispatch({ type: OAUTH_APPROVAL_SUCCESS, payload: data });
      dispatch(loginAuthenticated(data));
      redirect('/'); // Redirect to home page
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        if (err.response.status === 400) {
          dispatch({ type: OAUTH_APPROVAL_FAILURE, payload: { message: 'used' } });
        } else if (err.response.status === 404) {
          dispatch({ type: OAUTH_APPROVAL_FAILURE, payload: { message: 'not_found' } });
        } else {
          publishServerError(err.response.data)(dispatch);
        }
      } else {
        publishServerError(err)(dispatch);
      }
    });
};

export const removeOAuthRequest = ({ id }) => (dispatch) => {
  dispatch({ type: OAUTH_REMOVE_REQUEST });
  return httpClient.delete('/user-oauth', { data: { id } })
    .then(() => {
      dispatch({ type: OAUTH_REMOVE_SUCCESS, payload: { id } });
    })
    .catch((err) => {
      dispatch({ type: OAUTH_REMOVE_FAILURE, payload: err });
      if (err && err.response && err.response.data) {
        publishServerError(err.response.data)(dispatch);
      } else {
        publishServerError(err)(dispatch);
      }
    });
};

// Super Admin manually approve
export const updateOAuthRequest = ({ id }) => (dispatch) => {
  dispatch({ type: OAUTH_UPDATE_REQUEST });
  return httpClient.post('/user-oauth', { id })
    .then(() => {
      dispatch({ type: OAUTH_UPDATE_SUCCESS, payload: { id } });
    })
    .catch((err) => {
      dispatch({ type: OAUTH_UPDATE_FAILURE, payload: err });
      if (err && err.response && err.response.data) {
        publishServerError(err.response.data)(dispatch);
      } else {
        publishServerError(err)(dispatch);
      }
    });
};

export const resendOAuthRequest = ({ id }) => (dispatch) => {
  dispatch({ type: OAUTH_APPROVAL_REQUEST });
  return httpClient.put('/user-oauth', { id })
    .then(() => {
      dispatch({ type: OAUTH_APPROVAL_SUCCESS, payload: { id } });
    })
    .catch((err) => {
      dispatch({ type: OAUTH_APPROVAL_FAILURE, payload: err });
      if (err && err.response && err.response.data) {
        publishServerError(err.response.data)(dispatch);
      } else {
        publishServerError(err)(dispatch);
      }
    });
};
