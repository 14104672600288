import React from 'react';
import moment from 'moment';

import AdminIcon from '../../../components/AdminIcon';
import AdminIconList from '../../../components/AdminIconList';
import EapDisplayDate from '../../../components/EapDisplayDate';
import { StaticEapTable } from '../../../components/StaticEapTable';
import ConfirmModal from '../../../components/ConfirmationModal';

const OauthTable = ({
  data,
  revokeOauthRequest,
  approveOauthRequest,
  resendOauthRequest,
}) => {
  const [showModal, setShowModal] = React.useState({ type: '', data: {} });
  const columns = [
    {
      Header: 'Unique Id',
      accessor: 'id'
    },
    {
      Header: 'Provider',
      accessor: 'provider'
    },
    {
      Header: 'Approved',
      accessor: 'status',
      Cell: ({ row }) => (
        <span>{row.original.approved ? 'Yes' : 'No'}</span>
      )
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      disableFilters: true,
      Cell: ({ row }) => (<EapDisplayDate date={row.original.createdAt} />),
      sortMethod: (a, b) => (moment.utc(a).diff(moment.utc(b)))
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      disableFilters: true,
      Cell: ({ row }) => (<EapDisplayDate date={row.original.updatedAt} />),
      sortMethod: (a, b) => (moment.utc(a).diff(moment.utc(b)))
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <AdminIconList>
          <AdminIcon
            onClick={() => setShowModal({ type: 'revoke', data: row.original })}
            icon="fa-trash"
            toolTip="Delete"
          />
          {
            row.original.approved ? (
              <AdminIcon
                onClick={() => setShowModal({ type: 'revoke', data: row.original })}
                icon="fa-times"
                toolTip="Revoke"
              />
            ) : (
              <AdminIcon
                onClick={() => setShowModal({ type: 'approve', data: row.original })}
                icon="fa-check"
                toolTip="Approve"
              />
            )
          }
          <AdminIcon
            onClick={() => setShowModal({ type: 'email', data: row.original })}
            icon="fa-envelope"
            toolTip="Resend"
          />
        </AdminIconList>
      ),
    }
  ];
  return (
    <>
      <StaticEapTable
        columns={columns}
        data={data}
      />
      <ConfirmModal
        showModal={showModal.type === 'revoke'}
        modalTitle="Revoke OAuth Request"
        messagePrompt={<p>Are you sure you want to revoke this OAuth request?</p>}
        closeModal={() => setShowModal({})}
        confirmAction={() => {
          revokeOauthRequest(showModal.data.id);
          setShowModal({});
        }}
      />
      <ConfirmModal
        showModal={showModal.type === 'approve'}
        modalTitle="Approve OAuth Request"
        messagePrompt={<p>Are you sure you want to approve this OAuth request?</p>}
        closeModal={() => setShowModal({})}
        confirmAction={() => {
          approveOauthRequest(showModal.data.id);
          setShowModal({});
        }}
      />
      <ConfirmModal
        showModal={showModal.type === 'revoke'}
        modalTitle="Revoke OAuth Request"
        messagePrompt={<p>Are you sure you want to unapprove this OAuth request?</p>}
        closeModal={() => setShowModal({})}
        confirmAction={() => {
          approveOauthRequest(showModal.data.id);
          setShowModal({});
        }}
      />
      <ConfirmModal
        showModal={showModal.type === 'email'}
        modalTitle="Resend Approval Email"
        messagePrompt={<p>Are you sure you want to resend the approval email?</p>}
        closeModal={() => setShowModal({})}
        confirmAction={() => {
          resendOauthRequest(showModal.data.id);
          setShowModal({});
        }}
      />
    </>
  );
};

export default OauthTable;
