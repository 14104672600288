import { connect } from 'react-redux';
// import _ from 'lodash';

import Clever from './components/CleverOauthComponent';
import { exchangeCleverTokens } from '../actions/login';

const mapStateToProps = (state) => ({
  authenticated: state.login.authenticated,
  approvalRequested: state.login.oauthApprovalRequest,
});

const mapDispatchToProps = (dispatch) => ({
  exchangeTokens: (code) => {
    dispatch(exchangeCleverTokens(code));
  }
});

const CleverOauthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Clever);

export default CleverOauthContainer;
