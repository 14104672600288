import { connect } from 'react-redux';
// import _ from 'lodash';

import Oauth from './components/OauthApprovalComponent';
import { approveOAuthRequest } from '../actions/userOAuth';

const mapStateToProps = (state) => ({
  error: state.userOauth.error,
});

const mapDispatchToProps = (dispatch) => ({
  approveOauthRequest: (code) => {
    dispatch(approveOAuthRequest({ code }));
  }
});

const OauthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Oauth);

export default OauthContainer;
