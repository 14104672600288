import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import ProgressSpinner from '../../components/ProgressSpinner';

const OauthApprovalComponent = ({
  approveOauthRequest,
  error,
}) => {
  const { code } = useParams();
  useEffect(() => {
    approveOauthRequest(code);
  }, []);
  if (error && error === 'used') {
    return (
      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1>OAuth Approval</h1>
        <p>Your request has been approved.</p>
        <Redirect to="/" />
      </div>
    );
  }
  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1>OAuth Approval</h1>
        <p>There was an error while processing your request.</p>
        <p>Please contact <a href="mailto:support@edits.net">support@edits.net</a> for assistance.</p>
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1>OAuth Approval</h1>
      <p>Please wait while we verify your request.</p>
      <ProgressSpinner />
    </div>
  );
};

export default OauthApprovalComponent;
