/* eslint-disable default-param-last */
import _ from 'lodash';

import {
  USER_DATA_RECEIVED,
  SUPER_LOAD_USER_SUCCESS,
} from '../actions/user';

import {
  OAUTH_APPROVAL_REQUEST,
  OAUTH_APPROVAL_SUCCESS,
  OAUTH_APPROVAL_FAILURE,
  OAUTH_REMOVE_SUCCESS,
  OAUTH_UPDATE_SUCCESS,
} from '../actions/userOAuth';

const userOauthReducer = (
  state = {
    loading: true,
    error: undefined,
    list: [],
    serverFiltered: []
  },
  action
) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case USER_DATA_RECEIVED: {
      newState.list = action.payload.oauth || [];
      return newState;
    }
    case SUPER_LOAD_USER_SUCCESS: {
      newState.list = action.payload.userData.oauth || [];
      return newState;
    }
    case OAUTH_REMOVE_SUCCESS: {
      newState.list = newState.list.filter((oauth) => oauth.id !== action.payload.id);
      return newState;
    }
    case OAUTH_UPDATE_SUCCESS: {
      newState.list = newState.list.map((oauth) => {
        if (oauth.id === action.payload.id) {
          return { ...oauth, approved: !oauth.approved };
        }
        return oauth;
      });
      return newState;
    }
    case OAUTH_APPROVAL_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case OAUTH_APPROVAL_SUCCESS: {
      newState.loading = false;
      return newState;
    }
    case OAUTH_APPROVAL_FAILURE: {
      newState.loading = false;
      if (action.payload && action.payload.message) {
        newState.error = action.payload.message;
      }
      return newState;
    }
    default:
      return newState;
  }
};

export default userOauthReducer;
