/* eslint default-param-last: 0 */
import _ from 'lodash';

import {
  ADD_NEW_PRODUCT_SUCCESS,
  NEW_PASSCODE_SUCCESS,
  DELETE_AVAILABLE_PRODUCTS_SUCCESS,
  INCREMENT_PRODUCTS_SUCCESS,
  LOAD_AVAILABLE_PRODUCTS_SUCCESS,
  TRANSFER_PRODUCTS_SUCCESS,
  TRANSFER_PRODUCTS_FAILURE,
  NEW_PASSCODE_FAILURE,
  UPDATE_PASSCODE_FAILURE,
  UPDATE_PASSCODE_REQUEST,
  UPDATE_PASSCODE_SUCCESS,
} from '../actions/availableProducts';

import {
  SEND_EXAMINEE_INVITES_SUCCESS,
} from '../actions/examineeInvites';

import {
  GET_GROUP_SUCCESS
} from '../actions/group';

const availableProductsReducer = (
  state = {
    list: [],
    errorMessage: null,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case GET_GROUP_SUCCESS: {
      return {
        ...state,
        list: action.payload.groupInfo.availableProducts
      };
    }
    case ADD_NEW_PRODUCT_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push(action.payload.product);
      return { ...state, list };
    }
    case NEW_PASSCODE_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const p = list.find((prod) => (prod.id === action.payload.availableProductId));
      if (p) {
        p.passcode = action.payload.newPasscode;
      }
      return { ...state, list };
    }
    case DELETE_AVAILABLE_PRODUCTS_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.remove(list, (prod) => (prod.id === action.payload.availableProductId));
      return { ...state, list };
    }
    case LOAD_AVAILABLE_PRODUCTS_SUCCESS: {
      const list = _.uniqBy([...action.payload.products, ...state.list], 'id');
      return { ...state, list };
    }
    case INCREMENT_PRODUCTS_SUCCESS: {
      const {
        availableProductId: id,
        selectedAmount: amount
      } = action.payload;
      const list = _.cloneDeep(state.list);
      const product = list.find((prod) => (prod.id === id));
      if (product !== undefined) {
        product.amount += amount;
      }
      return { ...state, list };
    }
    case SEND_EXAMINEE_INVITES_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const srcProd = _.find(list, { id: action.payload.availableProductId });
      if (srcProd === undefined) {
        return { ...state };
      }
      srcProd.amount -= 1;
      return {
        ...state,
        list
      };
    }
    case TRANSFER_PRODUCTS_SUCCESS: {
      const {
        availableProductId: sourceId,
        selectedAmount: amount
      } = action.payload;
      const list = _.cloneDeep(state.list);
      const srcProd = list.find((prod) => (prod.id === sourceId));
      srcProd.amount -= amount;
      return { ...state, list };
    }
    case TRANSFER_PRODUCTS_FAILURE: {
      return { ...state, errorMessage: action.payload.error };
    }
    case NEW_PASSCODE_FAILURE: {
      return { ...state, errorMessage: action.payload.error };
    }
    case UPDATE_PASSCODE_REQUEST: {
      return { ...state, errorMessage: null, loading: true };
    }
    case UPDATE_PASSCODE_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const p = list.find((prod) => (prod.id === action.payload.productId));
      if (p) {
        p.passcode = action.payload.passcode;
      }
      return { ...state, list, loading: false };
    }
    case UPDATE_PASSCODE_FAILURE: {
      return { ...state, errorMessage: action.payload.error, loading: false };
    }
    default: {
      return { ...state };
    }
  }
};

export default availableProductsReducer;
