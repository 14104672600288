import { connect } from 'react-redux';
import EditSignin from './EditSignin';
import { updateSignin } from '../actions/user';
import { removeOAuthRequest } from '../actions/userOAuth';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    user: state.user,
    oauth: state.userOauth.list
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSignin: (updatedUserInfo) => {
    dispatch(updateSignin(updatedUserInfo));
  },
  removeOauth: (oauthId) => {
    dispatch(removeOAuthRequest({ id: oauthId }));
  }
});

const EditSigninContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSignin);

export default EditSigninContainer;
